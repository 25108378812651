<template lang="pug">
head-meta(title-page="Восстановление доступ" )
the-forgot-password
contacts-line
</template>

<script>
import TheForgotPassword from '../components/login/TheForgotPassword'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'TheForgotPasswordPage',
  components: { ContactsLine, HeadMeta, TheForgotPassword }
}
</script>
