<template lang="pug">
article.login
  .login__cont.cont(v-if="!done")
    h1.login__title.title Восстановление пароля
    p.login__text.text-big
        | Введите электронную почту, с&nbsp;которой вы регистрировались.
        br
        | На неё мы вышлем временный пароль
    form.login__form(@submit.prevent="submitForm")
      input.login__input.text.input(type='email', v-model="email", placeholder='Электронная почта*', required=true)
      span.login__invalid-password.text(v-if="error") Пользователь не существует
      button.login__button.button-white(:disabled="!email.length") Восстановить пароль
    ul.login__list
      li.login__item
        router-link.login__link.link-dot_white.text(:to="{ name: 'Login' }") Вход
      li.login__item
        router-link.login__link.link-dot_white.text(:to="{ name: 'Registration' }") Регистрация
  .login__cont.cont(v-else)
    h1.login__title.title Подтвердите почту
    p.login__text.text-big Отправили письмо с новым паролем на {{ email }}.
    p.login__text.text-big
      | Если письма нет в ящике, проверьте папку «Спам».
      br
      | Если там тоже нет, напишите на почту
      |
      a.login__mail-link.link_white(:href="mail.link") {{mail.mask}}
      br
      | ответим в ближайшее время
</template>

<script>
import '@/assets/styles/components/login.sass'
import { passwordForgot } from '../../assets/scripts/API'
import { mail } from '../../assets/scripts/variables'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      done: false,
      email: '',
      error: false,
      mail
    }
  },
  methods: {
    async submitForm () {
      const res = await passwordForgot(this.email)
      if (res.status === 'success') this.done = true
      else this.error = true
    }
  }
}
</script>
